<template>
    <div class="container mx-auto px-4 py-8">
        <div class="max-w-2xl mx-auto bg-white rounded-lg shadow-lg p-6">
            <h1 class="text-3xl font-bold text-gray-900 mb-4">
                Random {{ materialTypeDisplay }} Material Sample
            </h1>
            
            <div v-if="loading" class="text-center py-8">
                <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
                <p class="mt-4 text-gray-600">Loading random material...</p>
            </div>

            <div v-else-if="error" class="text-center py-8">
                <p class="text-red-500">{{ error }}</p>
                <button @click="fetchRandomSample" class="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                    Try Again
                </button>
            </div>

            <div v-else>
                <div class="bg-gray-100 p-4 rounded-lg">
                    <div class="grid grid-cols-[180px_minmax(0,320px)] gap-2">
                        <div v-if="randomSample.doi" class="py-0.5 text-right pr-2">
                            <span class="font-semibold">DOI:</span>
                        </div>
                        <div v-if="randomSample.doi" class="py-0.5 text-left">
                            <a :href="`https://doi.org/${randomSample.doi}`" 
                               target="_blank" 
                               class="text-blue-600 hover:text-blue-800 hover:underline">
                                {{ randomSample.doi }}
                            </a>
                        </div>

                        <div v-if="randomSample.title" class="py-0.5 text-right pr-2">
                            <span class="font-semibold">Title:</span>
                        </div>
                        <div v-if="randomSample.title" class="py-0.5 text-left">
                            <span>{{ randomSample.title }}</span>
                        </div>

                        <div v-if="randomSample.authors" class="py-0.5 text-right pr-2">
                            <span class="font-semibold">Authors:</span>
                        </div>
                        <div v-if="randomSample.authors" class="py-0.5 text-left">
                            <span>{{ Array.isArray(randomSample.authors) ? randomSample.authors.join('; ') : randomSample.authors }}</span>
                        </div>

                        <div v-if="randomSample.journal" class="py-0.5 text-right pr-2">
                            <span class="font-semibold">Journal:</span>
                        </div>
                        <div v-if="randomSample.journal" class="py-0.5 text-left">
                            <span>{{ randomSample.journal }}</span>
                        </div>

                        <div v-if="randomSample.year" class="py-0.5 text-right pr-2">
                            <span class="font-semibold">Year:</span>
                        </div>
                        <div v-if="randomSample.year" class="py-0.5 text-left">
                            <span>{{ randomSample.year }}</span>
                        </div>

                        <div v-if="randomSample.materials" class="py-0.5 text-right pr-2">
                            <span class="font-semibold">Materials:</span>
                        </div>
                        <div v-if="randomSample.materials" class="py-0.5 text-left">
                            <span>{{ Array.isArray(randomSample.materials) ? randomSample.materials.join(', ') : randomSample.materials }}</span>
                        </div>

                        <template v-if="randomSample.properties">
                            <div class="col-span-2 mt-2">
                                <span class="font-semibold">Properties:</span>
                                <div class="ml-4 mt-1 grid grid-cols-[180px_minmax(0,220px)] gap-1">
                                    <template v-for="(value, key) in randomSample.properties" :key="key">
                                        <div class="font-medium py-0.5 text-right pr-2">{{ key }}:</div>
                                        <div class="py-0.5 break-words text-left">{{ value }}</div>
                                    </template>
                                </div>
                            </div>
                        </template>

                        <template v-if="randomSample.abstract">
                            <div class="col-span-2 mt-2">
                                <span class="font-semibold">Abstract:</span>
                                <p class="mt-1 text-gray-700 text-left">{{ randomSample.abstract }}</p>
                            </div>
                        </template>

                        <template v-for="(value, key) in otherFields" :key="key">
                            <div class="py-0.5 text-right pr-2">
                                <span class="font-semibold">{{ formatFieldName(key) }}:</span>
                            </div>
                            <div class="py-0.5 break-words text-left">{{ formatValue(value) }}</div>
                        </template>
                    </div>
                </div>

                <div class="mt-4 flex justify-between">
                    <button @click="fetchRandomSample" class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                        Get Another Sample
                    </button>
                    <button @click="goBack" class="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded">
                        Go Back
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RandomSamplePage',
    data() {
        return {
            randomSample: null,
            loading: true,
            error: null,
            materialType: this.$route.params.type || 'magnetic',
            commonFields: ['doi', 'title', 'authors', 'journal', 'year', 'materials', 'properties', 'abstract']
        }
    },
    computed: {
        materialTypeDisplay() {
            return this.materialType.charAt(0).toUpperCase() + this.materialType.slice(1);
        },
        apiEndpoint() {
            const endpoints = {
                magnetic: '/backend/magnetic_random',
                thermoelectric: '/backend/thermoelectric_random'
            };
            return endpoints[this.materialType] || endpoints.magnetic;
        },
        otherFields() {
            if (!this.randomSample) return {};
            return Object.keys(this.randomSample)
                .filter(key => !this.commonFields.includes(key))
                .reduce((obj, key) => {
                    obj[key] = this.randomSample[key];
                    return obj;
                }, {});
        }
    },
    methods: {
        async fetchRandomSample() {
            this.loading = true;
            this.error = null;
            try {
                const response = await fetch(this.apiEndpoint);
                if (!response.ok) {
                    throw new Error('Failed to fetch random sample');
                }
                this.randomSample = await response.json();
            } catch (error) {
                this.error = 'Error loading random sample: ' + error.message;
                console.error('Error:', error);
            } finally {
                this.loading = false;
            }
        },
        formatFieldName(name) {
            return name
                .split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        },
        formatValue(value) {
            if (Array.isArray(value)) {
                return value.join(', ');
            } else if (typeof value === 'object' && value !== null) {
                return JSON.stringify(value, null, 2);
            }
            return value;
        },
        goBack() {
            this.$router.push('/');
        }
    },
    mounted() {
        this.fetchRandomSample();
    },
    watch: {
        '$route.params.type': {
            handler(newType) {
                this.materialType = newType;
                this.fetchRandomSample();
            }
        }
    }
}
</script>
