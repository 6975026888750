<template>
  <div class="periodic-table-wrapper bg-gradient-to-br from-blue-100 via-white to-blue-100 p-2 sm:p-4 md:p-6 rounded-lg shadow-md">
    <h2 class="text-xl sm:text-2xl md:text-3xl font-bold text-blue-900 mb-2 sm:mb-4 md:mb-6 text-center">Interactive Periodic Table</h2>
    <p class="text-sm sm:text-base md:text-lg text-gray-700 mb-2 sm:mb-4 md:mb-6 text-center">Click on an element to search for related materials</p>
    
    <div id="periodic-table" class="relative flex flex-wrap justify-center items-center mx-auto">
      <div v-for="(row, rowIndex) in symbol" :key="`row-${rowIndex}`" class="flex justify-center">
        <div v-for="(elementSymbol, index) in row" :key="`element-${elementSymbol}-${index}`"
          class="element relative flex items-center justify-center text-center transition-all duration-300 ease-in-out"
          :class="`border-0 m-0 font-bold ${isInteractiveElement(elementSymbol) ? 'interactive' : 'non-interactive'}`"
          :style="{ 
            backgroundColor: getColor(color[rowIndex][index]),
            width: `${elementSize}px`,
            height: `${elementSize * 0.875}px`,
            fontSize: `${elementSize * 0.3}px`
          }"
          @mouseover="isInteractiveElement(elementSymbol) && showMass(rowIndex, index)"
          @mouseleave="isInteractiveElement(elementSymbol) && hideMass()"
          @click="isInteractiveElement(elementSymbol) && searchElement(elementSymbol)">
          {{ elementSymbol }}
          <div v-if="isInteractiveElement(elementSymbol) && activeMassRow === rowIndex && activeMassIndex === index && materials_number[rowIndex][index] !== 0"
            class="mass-tooltip absolute bg-gradient-to-br from-blue-500 to-teal-500 text-white p-1 rounded-lg shadow-2xl -translate-y-full left-1/2 transform -translate-x-1/2 mt-1 text-left"
            :style="{
              minWidth: `${elementSize * 2}px`,
              fontSize: `${elementSize * 0.2}px`,
              zIndex: 10
            }">
            <div class="font-bold">Symbol: {{ symbol[rowIndex][index] }}</div>
            <div># of Materials: {{ materials_number[rowIndex][index].toFixed(0) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const router = useRouter();
    const windowWidth = ref(window.innerWidth);

    const updateWindowWidth = () => {
      windowWidth.value = window.innerWidth;
    };

    const elementSize = computed(() => {
      if (windowWidth.value < 640) return 20; // 小屏幕
      if (windowWidth.value < 768) return 25; // 中等屏幕
      if (windowWidth.value < 1024) return 35; // 大屏幕
      if (windowWidth.value < 1280) return 45; // 新增：介于大屏幕和超大屏幕之间
      return 64; // 超大屏幕
    });

    // 在组件挂载时添加事件监听器
    onMounted(() => {
      window.addEventListener('resize', updateWindowWidth);
    });

    // 在组件卸载时移除事件监听器
    onUnmounted(() => {
      window.removeEventListener('resize', updateWindowWidth);
    });

    return {
      router,
      elementSize
    };
  },
  data() {
    return {
      symbol: [['H', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'He'],
      ['Li', 'Be', '', '', '', '', '', '', '', '', '', '', 'B', 'C', 'N', 'O', 'F', 'Ne'],
      ['Na', 'Mg', '', '', '', '', '', '', '', '', '', '', 'Al', 'Si', 'P', 'S', 'Cl', 'Ar'],
      ['K', 'Ca', 'Sc', 'Ti', 'V', 'Cr', 'Mn', 'Fe', 'Co', 'Ni', 'Cu', 'Zn', 'Ga', 'Ge', 'As', 'Se', 'Br', 'Kr'],
      ['Rb ', 'Sr', 'Y', 'Zr', 'Nb', 'Mo', 'Tc', 'Ru', 'Rh', 'Pd', 'Ag', 'Cd', 'In', 'Sn', 'Sb', 'Te', 'I', 'Xe'],
      ['Cs', 'Ba', '', 'Hf', 'Ta', 'W', 'Re', 'Os', 'Ir', 'Pt', 'Au', 'Hg', 'Tl', 'Pb', 'Bi', 'Po', 'At', 'Rn'],
      ['Fr', 'Ra', '', 'Rf', 'Db', 'Sg', 'Bh', 'Hs', 'Mt', 'Ds', 'Rg', 'Cn', 'Nh', 'Fl', 'Mc', 'Lv', 'Ts', 'Og'],
      ['', '', 'La', 'Ce', 'Pr', 'Nd', 'Pm', 'Sm', 'Eu', 'Gd', 'Tb', 'Dy', 'Ho', 'Er', 'Tm', 'Yb', 'Lu', ''],
      ['', '', 'Ac', 'Th', 'Pa', 'U', 'Np', 'Pu', 'Am', 'Cm', 'Bk', 'Cf', 'Es', 'Fm', 'Md', 'No', 'Lr', ''],
      ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      ['', 'Alkali Metal', '', '', 'Transition Metal', '', '', 'Actinide', '', '', 'Semimetal', '', '', 'Halogen', '', '', '', ''],
      ['', 'Alkaline Metal', '', '', 'Lanthanide', '', '', 'Basic Metal', '', '', 'Nonmetal', '', '', 'Noble Gas', '', '', '', '']
      ],
      materials_number: [[1.00794, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, 4.002602],
      [6.941, 9.012182, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, 10.811, 12.0107, 14.0067, 15.9994, 18.9984032, 20.1797],
      [22.98976928, 24.3050, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, 26.9815386, 28.0855, 30.973762, 32.065, 35.453, 39.948],
      [39.0983, 40.078, 44.955912, 47.867, 50.9415, 51.9961, 54.938045, 55.845, 58.933195, 58.6934, 63.546, 65.38, 69.723, 72.64, 74.92160, 78.96, 79.904, 83.798],
      [85.4678, 87.62, 88.90585, 91.224, 92.90638, 95.96, 98, 101.07, 102.90550, 106.42, 107.8682, 112.411, 114.818, 118.710, 121.760, 127.60, 126.90447, 131.293],
      [132.9054519, 137.327, .0, 178.49, 180.94788, 183.84, 186.207, 190.23, 192.217, 195.084, 196.966569, 200.59, 204.3833, 207.2, 208.98040, 209, 210, 222],
      [223, 226, .0, 267, 268, 271, 272, 270, 276, 281, 280, 285, 286, 289, 289, 293, 294, 294],
      [.0, .0, 138.90547, 140.116, 140.90765, 144.242, 145, 150.36, 151.964, 157.25, 158.92535, 162.500, 164.93032, 167.259, 168.93421, 173.054, 174.9668, .0],
      [.0, .0, 227, 232.03806, 231.03588, 238.02891, 237, 244, 243, 247, 247, 251, 252, 257, 258, 259, 262, .0],
      [.0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0],
      [.0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0],
      [.0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0, .0]
      ],
      color: [[8, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
      [1, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 7, 8, 8, 8, 9, 1],
      [1, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6, 7, 8, 8, 9, 1],
      [1, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 6, 7, 8, 8, 9, 1],
      [1, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 6, 6, 7, 7, 9, 1],
      [1, 2, 4, 3, 3, 3, 3, 3, 3, 3, 3, 3, 6, 6, 6, 7, 9, 1],
      [1, 2, 5, 3, 3, 3, 3, 3, 3, 3, 3, 3, 6, 6, 6, 6, 9, 1],
      [0, 0, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 0],
      [0, 0, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [1, 1, 1, 3, 3, 3, 5, 5, 5, 7, 7, 7, 9, 9, 9, 0, 0, 0],
      [2, 2, 2, 4, 4, 4, 6, 6, 6, 8, 8, 8, 1, 1, 1, 0, 0, 0]
      ],
      activeMassRow: null,
      activeMassIndex: null,
    };
  },
  mounted() {
    this.fetchPeriodicTableNumber();
  },
  methods: {
    getColor(colorIndex) {
      const colors = [
        "#fafafa", "#f87171", "#fb923c", "#a3e635", 
        "#4ade80", "#34d399", "#22d3ee", "#818cf8", 
        "#c084fc", "#fda4af", "#e5e5e5"
      ];
      return colors[colorIndex];
    },
    showMass(rowIndex, index) {
      this.activeMassRow = rowIndex;
      this.activeMassIndex = index;
    },
    hideMass() {
      this.activeMassRow = null;
      this.activeMassIndex = null;
    },
    searchElement(elementSymbol) {
      if (this.isInteractiveElement(elementSymbol)) {
        this.router.push({ 
          name: 'SearchPage', 
          query: { element: elementSymbol } 
        });
      }
    },
    async fetchPeriodicTableNumber() {
      try {
        const url = '/backend/periodic_number';
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const newData = Object.values(await response.json());

        let newDataIndex = 0;

        for (let rowIndex = 0; rowIndex < this.materials_number.length; rowIndex++) {
          for (let index = 0; index < this.materials_number[rowIndex].length; index++) {
            if (this.materials_number[rowIndex][index] !== 0) {
              if (newDataIndex < newData.length) {
                this.materials_number[rowIndex][index] = newData[newDataIndex++];
              } else {
                console.error('新数据数组中的元素不足以更新所有非零位置');
                break;
              }
            }
          }
        }
      } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
      }
    },
    isInteractiveElement(elementSymbol) {
      const nonInteractiveElements = [
        'Alkali Metal', 'Alkaline Metal', 'Transition Metal', 'Lanthanide',
        'Actinide', 'Basic Metal', 'Semimetal', 'Nonmetal', 'Halogen', 'Noble Gas'
      ];
      return elementSymbol && elementSymbol.trim() !== '' && !nonInteractiveElements.includes(elementSymbol);
    },
  },
};
</script>

<style scoped>
.periodic-table-wrapper {
  max-width: 100%;
  overflow-x: auto;
}

.element.interactive:hover {
  z-index: 10;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transform: translateY(-4px);
  cursor: pointer;
}

.element.non-interactive {
  cursor: default;
}

@media (max-width: 1280px) {
  .periodic-table-wrapper {
    font-size: 0.9rem;
  }
}

@media (max-width: 1024px) {
  .periodic-table-wrapper {
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  .periodic-table-wrapper {
    font-size: 0.7rem;
  }
}

@media (max-width: 640px) {
  .periodic-table-wrapper {
    font-size: 0.6rem;
  }
}
</style>